import axiosInstance from '~/api/axios'
import type {Diner, Group, InstitutionDiner} from '~/types/diner.type'

export interface DinerGroup<D extends Diner = Diner> {
  diningParticipants: DinerGroupPair<D>[]
}
interface DinerGroupPair<D extends Diner = Diner> {
  diningParticipantId: number
  groups: Group<D>[]
}

class GroupModule {
  fetchGroups(customerId: number) {
    return axiosInstance.get<DinerGroup<InstitutionDiner>>(`/customer/${customerId}/groups`)
  }

  fetchInstitutionGroups(institutionId: number) {
    return axiosInstance.get<Group<InstitutionDiner>[]>(`/institution/${institutionId}/groups`)
  }

  editGroupForDiners(institutionId: number, params: EditGroupParams) {
    return axiosInstance.put<InstitutionDiner[]>(`/institution/${institutionId}/groups`, {...params})
  }
}

export interface EditGroupParams {
  groupId: number
  diningParticipantIds: number[]
  changeAt?: string
}

const module = new GroupModule()
export default module
